import React from "react";
import styled from "@emotion/styled"

const ContainerWrapper = styled("div")`
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  @media screen and (max-width: 550px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

const Container = ({ children }) => {
  return (
    <ContainerWrapper>
      {children}
    </ContainerWrapper>
  );
}

export default Container;

import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import styled from '@emotion/styled';

const GraphWrapper = styled("div")`
  font-size: .8rem;
  margin-bottom: 1rem;
  display: inline-block;
  overflow-x: hidden;
  max-width: 100%;
`

const GraphContent = styled("div")`
  display: flex;
  align-items: flex-end;
  direction: rtl;
  text-align: center;
  overflow-x: scroll;
  width: 100%;
  a {
    flex: 1;
    padding-top: 3px;
    width: 30px;
    background-color: #f9d83d;
    display: block;
    text-decoration: none;
    color: #201D1D;
    text-align: center;
    font-weight: 700;
    &:hover {
      background-color: #201D1D;
      color: #ffffff;
    }
  }
`;

const GraphLine = styled("div")`
  margin: 0 5px;
`
const GraphDate = styled("div")`
  padding-bottom: 1rem;
`
const GraphDescription = styled("div")`
  display: flex;
  align-items: center;
  margin-top: 1rem;
   p {
    margin-bottom: 0;
  }
`

const GraphDescriptionBar = styled("div")`
  width: 50px;
  height: 20px;
  background-color: #f9d83d;
  margin-right: 1rem;
`

class Graph extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <GraphWrapper>
        <GraphContent>
          {posts &&
            posts.map(({ node: post }) => (
              <GraphLine>
                <Link css={{
                  height: `${post.frontmatter.days}px`
                }}
                  to={post.fields.slug}>
                  {post.frontmatter.days}
                </Link>
                <GraphDate>
                  {post.frontmatter.date}
                </GraphDate>
              </GraphLine>
            ))}
        </GraphContent>
        <GraphDescription><GraphDescriptionBar /><p>Dny práce na stát (<em>Pro více informací klikněte na příslušný rok</em>)</p></GraphDescription>
      </GraphWrapper>
    );
  }
}

Graph.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

export default () => (
  <StaticQuery
    query={graphql`
      query GraphQuery {
        allMarkdownRemark(
          limit: 1000
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                date(formatString: "YYYY")
                days
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <Graph data={data} count={count} />}
  />
);

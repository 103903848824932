import React from "react"
import { Link } from "gatsby"

import Container from './Container'
import logo from "../img/logo.svg"
import styled from '@emotion/styled'

const Navbar = styled("header")`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
`

const Nav = styled("nav")`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #ffffff;
  min-height: 41px;
`

const Menu = styled("ul")`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0 1rem;
  @media screen and (max-width: 550px) {
    padding: 0 .5rem;
  }
`;

const MenuItem = styled("li")`
  a {
    padding-left: 1rem;
    padding-right: 1rem;
    font-weight: 700;
    text-decoration: none;
    color: #000000;
    &:hover {
      text-decoration: none;
      color: #201D1D;
    }

    @media screen and (max-width: 550px) {
      padding-left: .5rem;
      padding-right: .5rem;
    }
  }
`;

const Logo = styled("img")`
  display: block;
  max-width: 100%;
  height: auto;
`

export default () => {

  return (
    <>
      <Container>
        <Navbar role="navigation" aria-label="main-navigation">
          <Link to="/" title="Logo">
            <Logo
              loading="lazy"
              src={logo}
              alt="Logo - Den daňové svobody"
              style={{ width: "285px" }}
            />
          </Link>
          <Nav>
            <Menu>
              <MenuItem>
                <Link to="/">Úvodní stránka</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/kontakt">Kontakt</Link>
              </MenuItem>
            </Menu>
          </Nav>
        </Navbar>
      </Container>
    </>
  );
};

import React from "react";
import { useStaticQuery, graphql } from "gatsby"
import styled from '@emotion/styled';
import Marked from 'react-markdown'

import Container from "./Container";
import Graph from "./Graph";

import methodicImage from "../img/methodic.svg";
import projectLargeImage from "../img/project.svg";
import projectSmallImage from "../img/project-small.svg";

const Block = styled("section")`
  margin-top: 6.25rem;
  margin-bottom: 6.25rem;
  @media screen and (max-width: 550px) {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
`;

const BlockContent = styled("div")`
  padding: 3rem;
  background-color: #ffffff;
  box-shadow: 0px 0px 40px 60px rgba(0, 0, 0, 0.05);
  @media screen and (max-width: 550px) {
    padding: 2rem 1.5rem;
    margin-left: -1rem;
    margin-right: -1rem;
  }

  h2 {
    margin-bottom: 1em;
  }
`;

const Row = styled("div")`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-left: -3rem;
  margin-right: -3rem;
  @media screen and (max-width: 550px) {
    margin-left: -1rem;
    margin-right: -1rem;
  }
`;

const Col6 = styled("div")`
  max-width: 50%;
  flex-basis: 50%;
  padding-left: 3rem;
  padding-right: 3rem;
  @media screen and (max-width: 1023px) {
    max-width: 100%;
    flex-basis: 100%;
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media screen and (max-width: 550px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const ColGraph = styled("div")`
  align-self: flex-end;
  max-width: 50%;
  flex-basis: 50%;
  padding-left: 3rem;
  padding-right: 3rem;
  @media screen and (max-width: 1023px) {
    max-width: 100%;
    flex-basis: 100%;
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media screen and (max-width: 550px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const ColLeftImage = styled("div")`
  max-width: 40%;
  flex-basis: 40%;
  padding-right: 3rem;
  align-self: center;
  img {
    width: 100%;
  }
`;

const ColRightImage = styled("div")`
  max-width: 50%;
  flex-basis: 50%;
  padding-left: 3rem;
  align-self: bottom;
  img {
    width: 100%;
  }
`;

const ColRight = styled("div")`
  max-width: 60%;
  flex-basis: 60%;
  padding-left: 3rem;
  padding-right: 3rem;
  @media screen and (max-width: 1023px) {
    max-width: 100%;
    flex-basis: 100%;
  }

  @media screen and (max-width: 550px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const ProjectLargeImage = styled("img")`
  display: block;
  @media screen and (max-width: 1023px) {
    display: none;
  }
`;

const ProjectSmallImage = styled("img")`
  display: none;
  @media screen and (max-width: 1023px) {
    display: block;
    max-width: 140px;
    position: absolute;
    right: 0px;
    top: -103px;
    transform: rotate(-20deg);
  }

  @media screen and (max-width: 550px) {
    max-width: 115px;
    transform: none;
    right: 0;
    top: -87px;
  }
`;

const MethodicImage = styled("img")`
  @media screen and (max-width: 1023px) {
    position: absolute;
    max-width: 141px;
    top: -90px;
    right: 3px;
  }

  @media screen and (max-width: 550px) {
    max-width: 100px;
    right: -5px;
    top: -60px;
  }
`;

export default () => {
  const data = useStaticQuery(graphql`
    query SectionQuery {
      markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
        frontmatter {
          section_what
          section_about
          section_methodic
        }
      }
    }
  `)

  return (
    <>
      <Block>
        <Container>
          <BlockContent>
            <Row>
              <Col6>
                <Marked source={data.markdownRemark.frontmatter.section_what} />
              </Col6>
              <ColGraph>
                <Graph />
              </ColGraph>
            </Row>
          </BlockContent>
        </Container>
      </Block>
      <Block>
        <Container>
          <BlockContent>
            <Row>
              <ColLeftImage>
                <ProjectLargeImage
                  loading="lazy"
                  alt="O projektu"
                  src={projectLargeImage}
                />
                <ProjectSmallImage
                  loading="lazy"
                  alt="O projektu"
                  src={projectSmallImage}
                />
              </ColLeftImage>
              <ColRight>
                <Marked source={data.markdownRemark.frontmatter.section_about} />
              </ColRight>
            </Row>
          </BlockContent>
        </Container>
      </Block>
      <Block>
        <Container>
          <BlockContent>
            <Row>
              <Col6>
                <Marked source={data.markdownRemark.frontmatter.section_methodic} />
              </Col6>
              <ColRightImage>
                <MethodicImage
                  loading="lazy"
                  alt="Metodika DDS"
                  src={methodicImage}
                />
              </ColRightImage>
            </Row>
          </BlockContent>
        </Container>
      </Block>
    </>
  );
};
